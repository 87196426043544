.card {
    /* Estilos generales de la tarjeta */
  }
  
  .card .row {
    margin-bottom: 20px; /* Espacio entre las filas */
  }
  
  @media (max-width: 768px) {
    .card .row {
      margin-bottom: 0 !important; /* Eliminar el espacio entre las filas en dispositivos móviles */
      display: flex; /* Cambiar el display de las filas a flexbox */
      flex-wrap: wrap; /* Permitir el ajuste de los elementos en varias líneas */
      justify-content: center; /* Centrar los elementos horizontalmente */
    }
  
    .card .col-12 {
      width: 100%; /* Ajustar el ancho de las columnas al 100% en dispositivos móviles */
      max-width: 100%; /* Ancho máximo de las columnas en dispositivos móviles */
      text-align: center; /* Centrar el contenido en dispositivos móviles */
      margin-bottom: 20px; /* Espacio entre las columnas en dispositivos móviles */
    }
  
    .card .col-12 img {
      width: 80%; /* Ancho de las imágenes en dispositivos móviles */
      max-width: 100%; /* Ancho máximo de las imágenes en dispositivos móviles */
      display: block; /* Asegurar que las imágenes sean bloques */
      margin: 0 auto; /* Centrar las imágenes horizontalmente en dispositivos móviles */
      border-radius: 10px; /* Agregar borde redondeado a las imágenes */
    }
  }
  