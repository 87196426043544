/* Estilos para el componente de cookies y privacidad */
.cookie-consent {
    position: fixed;
    bottom: 10px; /* Ajustar la distancia desde abajo */
    left: -300px; /* Inicialmente, el componente estará fuera de la pantalla */
    width: 280px; /* Ancho del rectángulo del componente */
    padding: 10px;
    background-color: rgba(123, 222, 193, 0.8); /* Color de fondo con transparencia */
    color: #000; /* Color de texto negro */
    transition: left 0.3s; /* Agregamos una transición para que el componente se deslice al mostrar/ocultar */
    z-index: 999; /* Aseguramos que esté por encima de otros elementos */
  }
  
  /* Estilos para mostrar el componente */
  .cookie-consent.show {
    left: 10px; /* Muestra el componente desplazándolo hacia la derecha */
  }
  
  /* Estilos para ocultar el componente */
  .cookie-consent.hide {
    left: -300px; /* Oculta el componente desplazándolo hacia la izquierda */
  }
  
  /* Estilos para el contenido del componente */
  .cookie-content {
    padding: 10px;
  }
  
  /* Estilos para el botón de aceptar */
  .cookie-content button {
    background-color: #000; /* Color de fondo negro */
    color: #fff; /* Color de texto blanco */
    border: none;
    padding: 8px 16px;
    cursor: pointer;
  }
  