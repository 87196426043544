.whatsapp-button {
    position: fixed;
    bottom: 30px;
    right: 30px;
    background-color: #25d366;
    color: #fff;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 10px;
    border-radius: 50%;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
    z-index: 9999;
  }
  
  .whatsapp-icon {
    font-size: 35px; /* Tamaño del ícono */
    /* Otros estilos para el ícono de WhatsApp */
  }