.pagination .active .page-link {
  color: $white;
}

.z-index-10 {
  z-index: 10 !important;
}

.timeline .ant-timeline-item-head {
  border-color: $green;
}

.w-50-px {
  width: 50px;
}

.h-50-px {
  height: 50px;
}

.w-70-px {
  width: 70px;
}

.h-70-px {
  height: 70px;
}

.w-100-px {
  width: 100px;
}

.h-100-px {
  height: 100px;
}

.w-150-px {
  width: 150px;
}

.h-150-px {
  height: 150px;
}

.w-200-px {
  width: 200px;
}

.h-200-px {
  height: 200px;
}

.w-300-px {
  width: 300px;
}

.h-300-px {
  height: 300px;
}

.w-400-px {
  width: 400px;
}
.w-450-px {
  width: 450px;
}

.h-400-px {
  height: 400px;
}
.fit-cover {
  object-fit: cover;
}

.m-auto {
  margin: auto;
}

.my-auto {
  margin: auto 0;
}

.mx-auto {
  margin: 0 auto;
}

.faded-white-horizontal {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

.faded-blue-horizontal {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: linear-gradient(to right, #27292c 0%, rgba(30, 41, 59, 0) 100%);
}

.root {
  overflow: hidden;
}

.border-none {
  border: transparent;
}

.bg-transparent {
  background-color: transparent;
}

.border-left {
  border-left: 1px solid #d9d9d9;
}

.grid-template-columns-4 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1em;
  @media (max-width: 400px) {
    display: block;
  }
}

.grid-template-columns-3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1em;
  @media (max-width: 400px) {
    display: block;
  }
}
.grid-template-columns-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1em;
  @media (max-width: 400px) {
    display: block;
  }
}

.image-item {
  justify-content: space-between;
  align-items: center;
}

.rdp-cell .rdp-button:not([disabled]) {
  background-color: #ffc107a6 !important;
}

.slick-slider {
  touch-action: none !important;
}
