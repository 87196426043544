.footer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin:1rem;
    gap:1rem;
    color:white;
    flex-direction: column;
  }

  .logo-container {
    display: flex;
    flex-wrap: wrap; /* Permitir que los elementos se envuelvan en filas */
    justify-content: center; /* Centrar los elementos horizontalmente */
  }
  
  .logo-item {
    margin: 10px; /* Espacio entre los elementos */
    text-align: center; /* Centrar texto */
  }
  
  .logo-item img {
    width: 50px; /* Ancho de la imagen */
    height: 18px; /* Alto de la imagen */
  }
  
  @media (max-width: 768px) {
    .logo-item {
      width: calc(50% - 20px); /* Dos elementos por fila con margen */
      /* o bien puedes probar con width: calc(33.33% - 20px); para tres elementos por fila */
    }
  }
  
  /* Estilos para los logos */
  
  /* Estilo para el logo activo (el que está siendo mostrado actualmente) */
