.circle {
  width: 350px;
  height: 350px;
  background-color: #7BDEC1;
  border-radius: 50%;
  position: fixed;
  top: 70%;
  right: 10%;
  transform: translate(-50%, -50%);
  animation: moveCircle 2s infinite;
  z-index: -1;
}

@keyframes moveCircle {
  0%, 100% {
    transform: translate(-50%, -50%) translate(0, 0);
  }
  25% {
    transform: translate(-50%, -50%) translate(150px, 0);
  }
  50% {
    transform: translate(-50%, -50%) translate(150px, 100px);
  }
  75% {
    transform: translate(-50%, -50%) translate(0, 100px);
  }
}

.triangle-yellow, .triangle-pink {
  background-image: url('./../images/icons/rayos_Rosa.png');
  background-size: cover;
  background-repeat: no-repeat;
  width: 400px; /* Ajusta el tamaño según lo desees */
  height: 600px; /* Ajusta el tamaño según lo desees */
  position: absolute;
  transform: translate(-50%, -50%) rotate(0deg);
  animation: rotateTriangle 10s linear infinite;
  z-index: -1;
}

@keyframes rotateTriangle {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.triangleBackgroundPink {
  width: 0;
  height: 0;
  border-left: 550px solid transparent;
  border-bottom: 450px solid; /* Triángulo en el color #FF5A88 */
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: -1;
}

