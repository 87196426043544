.card {
  &.card-product {
    img {
      object-fit: cover;
      height: 100%;
    }
  }

  .badge {
    border: 1px solid $light;
    border-radius: 50%;
    cursor: pointer;
    display: inline-block;
    height: 23px;
    margin-right: 5px;
    position: relative;
    width: 23px;
    transition: $transition-base;

    &:hover,
    &.active {
      border-color: $dark;
    }
  }
}

.card-product.hover-effect .card-body {
  transition: transform 0.3s ease;
}

.card-product.hover-effect:hover .card-body {
  transform: scale(1.05);
}
